<template>
    <v-container>
        <div class="d-flex flex-column align-center justify-center mt-2">
            <div class="d-flex flex-column align-center justify-center" style="display: block;width: 300px">
                <div>
                    <v-btn v-print="printObj" color="primary">สั่งพิมพ์เอกสาร</v-btn>
                </div>
                <div>
                    <v-radio-group v-model="langSelection" hide-details row>
                        <v-radio
                                label="ไทย"
                                :value="1"
                        ></v-radio>
                        <v-radio
                                label="한국"
                                :value="2"
                        ></v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div id="printMe" class="setfont" v-if="langSelection===1">
                <div v-for="(item,index) in dataPrint" :key="index" class="elevation-4 mt-2 rounded-lg"
                     style="background: #fff; width: 80mm;height: 250mm;padding:10px 10px 10px 10px">
                    <h3>
                        {{ shopData.shop_name }}
                    </h3>
                    <div class="d-flex">
                        <h4>ทะเบียนการค้า :</h4>
                        <div class="font-weight-normal">{{ shopData.shop_tax_number }}</div>
                    </div>
                    <div class="d-flex">
                        <h4 style="white-space: nowrap">ที่อยู่ : </h4>
                        <div>{{ shopData.shop_address_ko }}</div>
                    </div>
                    <div class="d-flex">
                        <h4 style="white-space: nowrap">
                            เบอร์โทรศัพท์ :
                        </h4>
                        <div>
                            {{ shopData.shop_phone }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4 style="white-space: nowrap">
                            วันที่สั่งซื้อ :
                        </h4>
                        <div>
                            {{ item.create_date_text }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4 style="white-space: nowrap">
                            พนักงานขาย :
                        </h4>
                        <div>
                            {{ item.user_create }}
                        </div>
                    </div>
                    <hr style="background: #000;height: 2px;margin-bottom: 4px">
                    <div class="d-flex">
                        <h4 style="white-space: nowrap">
                            ชื่อลูกค้า :
                        </h4>
                        <div>
                            {{ item.customer_fname }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            เลขที่ออเดอร์ :
                        </h4>
                        <div>
                            {{ item.order_code }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4 style="white-space: nowrap">
                            ชื่อผู้รับสินค้า :
                        </h4>
                        <div>
                            {{ item.order_transportation_name }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            เบอร์โทร :
                        </h4>
                        <div>
                            {{
                                item.order_transportation_phone
                            }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4 style="white-space: nowrap">
                            ที่อยู่จัดส่ง :
                        </h4>
                        <div>
                            {{ item.order_transportation_full_address }}
                        </div>
                    </div>
                    <hr style="background: #000;height: 2px;">
                    <v-row no-gutters style="font-size: 11px">
                        <v-col cols="4" style="font-weight: bold">รายการ</v-col>
                        <v-col style="font-weight: bold;text-align: center">จำนวน</v-col>
                        <v-col style="font-weight: bold;text-align: center">ราคา</v-col>
                        <v-col style="font-weight: bold;text-align: right">รวม</v-col>
                    </v-row>
                    <v-row no-gutters v-for="(i,x) in item.order_detail_arr" style="font-size: 11px">
                        <v-col cols="4">{{ i[0] }}</v-col>
                        <v-col style="text-align: center">{{ i[1] }}</v-col>
                        <v-col style="text-align: center">{{ i[2] }}</v-col>
                        <v-col style="text-align: center">{{ i[3] }}</v-col>
                    </v-row>
                    <hr style="background: #000;height: 2px;margin-bottom: 2px">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                ค่าจัดส่ง
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.order_transportation_price |formatPrice }}
                            <CurrentCurrency/>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                จำนวน
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.order_transportation_amount }}
                            QTY.
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                รวม
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.order_transportation_total |formatPrice }}
                            <CurrentCurrency/>
                        </v-col>
                    </v-row>
                    <hr style="backgroun: #000;height: 2px;margin-bottom: 2px">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                ราคารวม
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.order_before_discount |formatPrice }}
                            <CurrentCurrency/>
                        </v-col>
                    </v-row>
                    <!--          <v-row no-gutters>-->
                    <!--            <v-col cols="6">-->
                    <!--              <h4>-->
                    <!--                ส่วนลด-->
                    <!--              </h4>-->
                    <!--            </v-col>-->
                    <!--            <v-col cols="6" style="text-align: right">-->
                    <!--              {{ item.order_discount |formatPrice }}-->
                    <!--              <CurrentCurrency/>-->
                    <!--            </v-col>-->
                    <!--          </v-row>-->
                    <!--          <v-row no-gutters>-->
                    <!--            <v-col cols="6">-->
                    <!--              <h4>-->
                    <!--                ราคารวมหลังหักส่วนลด-->
                    <!--              </h4>-->
                    <!--            </v-col>-->
                    <!--            <v-col cols="6" style="text-align: right">-->
                    <!--              {{ item.order_after_discount |formatPrice }}-->
                    <!--              <CurrentCurrency/>-->
                    <!--            </v-col>-->
                    <!--          </v-row>-->
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                ภาษีมูลค่าเพิ่ม {{ item.order_vat }} %
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.order_vat_value |formatPrice }}
                            <CurrentCurrency/>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                ราคาสุทธิ
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.sum_total_transportation |formatPrice }}
                            <CurrentCurrency/>
                        </v-col>
                    </v-row>
                    <hr style="backgroun: #000;height: 2px;margin-bottom: 2px">
                    <div class="d-flex">
                        <h4 style="white-space: nowrap">
                            ชื่อธนาคาร :
                        </h4>
                        <div>
                            {{ item.bank_full_name }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4 style="white-space: nowrap">
                            เลขที่บัญชี :
                        </h4>
                        <div>
                            <span v-if="item.bank_number  && item.bank_number.length">

                            {{ item.bank_number.substring(0, item.bank_number.length - 4) }}xxxx
                            </span>
                            <span v-else>

                            </span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            จำนวนที่โอน :
                        </h4>
                        <div>
                            {{ item.order_payment_total |formatPrice }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            ชื่อผู้โอน :
                        </h4>
                        <div>
                            {{ item.order_payment_slip_confirm }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4 style="white-space: nowrap">
                            เวลาที่โอน :
                        </h4>
                        <div>
                            {{ item.order_payment_date }}
                        </div>
                    </div>
                    <hr style="backgroun: #000;height: 2px;margin-bottom: 2px">
                    <div class="d-flex">
                        <h4>
                            ช่องทางการจัดส่ง :
                        </h4>
                        <div>
                            {{ item.transport_name }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            วันที่จัดส่ง :
                        </h4>
                        <div>
                            {{ item.order_transportation_date }}
                        </div>
                    </div>
                    <div>
                        สินค้าถึงลูกค้า 1 วันหลังจัดส่ง
                    </div>
                    <div>
                        เวลาในการนำจ่ายของบริษัทขนส่ง 09.00น.-20.00น.
                    </div>
                    <br>
                    <div>
                        ลิงค์ติดตามสถานะการจัดส่ง
                    </div>
                    <div>
                        สินค้าเข้าระบบ :{{ item.tracking_time }}
                    </div>
                    <div>
                        {{ item.link.substr(0, 35) }}
                        {{ item.link.substr(35, 100) }}
                    </div>
                </div>
            </div>
            <div id="printMe" class="setfont" v-if="langSelection===2">
                <div v-for="(item,index) in dataPrint" :key="index" class="elevation-4 mt-2 rounded-lg"
                     style="background: #fff; width: 80mm;height: 250mm;padding:10px 10px 10px 10px">
                    <h3>
                        {{ shopData.shop_name }}
                    </h3>
                    <div class="d-flex">
                        <h4>
                            사업자번호 :
                        </h4>
                        <div>
                            {{ shopData.shop_tax_number }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            주소 :
                        </h4>
                        <div>
                            {{ shopData.shop_address_ko }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            전화번호 :
                        </h4>
                        <div>
                            {{ shopData.shop_phone }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            판매일 :
                        </h4>
                        <div>
                            {{ item.create_date_text }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            판매원 :
                        </h4>
                        <div>
                            {{ item.user_create }}
                        </div>
                    </div>
                    <hr style="background: #000;height: 2px;margin-bottom: 4px">
                    <div class="d-flex">
                        <h4>
                            주문자 :
                        </h4>
                        <div>
                            {{ item.customer_fname }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            주문번호 :
                        </h4>
                        <div>
                            {{ item.order_code }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            받는분 :
                        </h4>
                        <div>
                            {{ item.order_transportation_name }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            전화번호 :
                        </h4>
                        <div>
                            {{
                                item.order_transportation_phone
                            }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            주소 :
                        </h4>
                        <div>
                            {{ item.order_transportation_full_address }}
                        </div>
                    </div>
                    <hr style="background: #000;height: 2px;">
                    <v-row no-gutters style="font-size: 11px">
                        <v-col cols="4" style="font-weight: bold">상품명</v-col>
                        <v-col style="font-weight: bold;text-align: center">단가</v-col>
                        <v-col style="font-weight: bold;text-align: center">수량</v-col>
                        <v-col style="font-weight: bold;text-align: right">금액</v-col>
                    </v-row>
                    <v-row no-gutters v-for="(i,x) in item.order_detail_arr" style="font-size: 11px">
                        <v-col cols="4">{{ i[0] }}</v-col>
                        <v-col style="text-align: center">{{ i[1] }}</v-col>
                        <v-col style="text-align: center">{{ i[2] }}</v-col>
                        <v-col style="text-align: center">{{ i[3] }}</v-col>
                    </v-row>
                    <hr style="background: #000;height: 2px;margin-bottom: 2px">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                택배배송
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.order_transportation_price |formatPrice }}
                            <CurrentCurrency/>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                수량
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.order_transportation_amount }}
                            QTY.
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                합계
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.order_transportation_total |formatPrice }}
                            <CurrentCurrency/>
                        </v-col>
                    </v-row>
                    <hr style="backgroun: #000;height: 2px;margin-bottom: 2px">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                할인 후 총 가격
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.order_after_discount |formatPrice }}
                            <CurrentCurrency/>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                큰 통 {{ item.order_vat }} %
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.order_vat_value |formatPrice }}
                            <CurrentCurrency/>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h4>
                                정가
                            </h4>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            {{ item.sum_total_transportation |formatPrice }}
                            <CurrentCurrency/>
                        </v-col>
                    </v-row>
                    <hr style="backgroun: #000;height: 2px;margin-bottom: 2px">
                    <div class="d-flex">
                        <h4>
                            입금은행 :
                        </h4>
                        <div>
                            {{ item.bank_full_name }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            수취인명 :
                        </h4>
                        <div>
                            <span v-if="item.bank_number  && item.bank_number.length">
                            {{ item.bank_number.substring(0, item.bank_number.length - 4) }}xxxx
                                </span>
                            <span v-else>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            입금금액 :
                        </h4>
                        <div>
                            {{ item.order_payment_total |formatPrice }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            송금인 :
                        </h4>
                        <div>
                            {{ item.order_payment_slip_confirm }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            거래일시 :
                        </h4>
                        <div>
                            {{ item.order_payment_date }}
                        </div>
                    </div>
                    <hr style="backgroun: #000;height: 2px;margin-bottom: 2px">
                    <div class="d-flex">
                        <h4>
                            배송자 :
                        </h4>
                        <div>
                            {{ item.transport_name }}
                        </div>
                    </div>
                    <div class="d-flex">
                        <h4>
                            배송일 :
                        </h4>
                        <div>
                            {{ item.order_transportation_date }}
                        </div>
                    </div>
                    <div>
                        평균 배송기간 1일이내
                    </div>
                    <div>
                        배송기간은 주말/공휴일을 제외한 영업일 기준
                    </div>
                    <br>
                    <div>
                        택배조회 링크
                    </div>
                    <div>
                        배송 추적 링크 :{{ item.tracking_time }}
                    </div>
                    <div>
                        {{ item.link.substr(0, 35) }}
                        {{ item.link.substr(35, 100) }}
                    </div>
                </div>
                <br>
            </div>
        </div>
    </v-container>
</template>

<script>
import print from 'vue-print-nb'
import { onMounted, ref, watch } from "@vue/composition-api"
import { api } from "@/services/api";
import { useRouter } from "@core/utils";
import { formatPrice, sumdatetime } from "@/services/filters";
import CurrentCurrency from "@/components/CurrentCurrency";

export default {
    directives: {
        print
    },
    props: {},
    components: { CurrentCurrency },
    filters: {
        formatPrice,
        sumdatetime
    },
    setup(_, { root, emit }) {
        const { route } = useRouter()
        const printLoading = ref(true)
        const dataPrint = ref([])
        const shopData = ref({})
        const langSelection = ref(2)
        const printObj = ref({
            id: "printMe",
            popTitle: '',
            extraCss: 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap,https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
            extraHead: '',
            beforeOpenCallback(vue) {
                vue.printLoading = true
                console.log('打开之前')
            },
            openCallback(vue) {
                vue.printLoading = false
                console.log('执行了打印')
            },
            closeCallback(vue) {
                console.log('关闭了打印工具')
            }
        })
        onMounted(() => {
            shopData.value = JSON.parse(localStorage.getItem('shop'))
            getPrintData('ko')
        })

        const getPrintData = (lang) => {
            api.get({
                path: '/printOrder',
                param: `?order_id_list=${ route.value.query.id }&lang=${ lang }&currency_id=${ localStorage.getItem('currencyId') }`,
            }).then(({ data }) => {
                dataPrint.value = data
                console.log('data:', data[0])
            }).catch((err) => {
                console.log(err)
            })
        }

        watch(langSelection, value => {
            if (value === 1) {
                getPrintData('th')
            } else {
                getPrintData('ko')
            }

        })


        return { printLoading, printObj, dataPrint, langSelection, shopData }
    },


}
</script>

<style scoped>
@media print {
    @page {
        size: 80mm 250mm;
        margin: 0;

    }
}


.setfont {
    font-family: 'Lucida Console';
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    color: #000;
}

.bl-1 {
    border-left: 1px solid #000;
}

.br-1 {
    border-right: 1px solid #000;
}

.bt-1 {
    border-top: 1px solid #000;
}

.bb-1 {
    border-bottom: 1px solid #000;
}

.b-1 {
    border: 1px solid #000;
}

.d-flex {
    display: flex;
}

.d-flex h4 {
    padding-right: 3px;
    white-space: nowrap;
}

.d-flex div {
    padding-top: 1px;
}

@font-face {
    font-family: 'Lucida Console';
    src: url('../assets/font/LucidaConsole.woff2') format('woff2'),
    url('../assets/font/LucidaConsole.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


</style>

